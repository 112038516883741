import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Container,
} from '@material-ui/core';
import { useTranslation } from '../hooks/useTranslation';
import Link from '../components/text-link';
import ROUTES from '../routes';
import { useLocation } from '@reach/router';

export default function NotFoundPage() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imageWrapper: {
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      links: {
        marginLeft: -20,
        '& li a': { fontSize: '1.3em' },
      },
    }),
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [
    caseInSensitiveURLCheckCompleted,
    setCaseInSensitiveURLCheckCompleted,
  ] = useState<boolean>(false);

  const [redirecting, setRedirecting] = useState<boolean>(false);

  useEffect(() => {
    //Redirecting users to lowercase URL to allow case insensitive routes
    if (pathname !== pathname.toLowerCase()) {
      //Need full reload to capture the server redirects (Like firebase redirects)
      window.location.href = pathname.toLowerCase();
      setRedirecting(true);
    }

    setCaseInSensitiveURLCheckCompleted(true);
  }, []);

  if (!caseInSensitiveURLCheckCompleted || redirecting) {
    return null;
  }

  return (
    <Container
      style={{
        paddingTop: 50,
        paddingBottom: 50,
        textAlign: 'center',
      }}
    >
      <Grid container spacing={1} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Grid item xs={12} sm>
          <Typography variant={'h1'} style={{ fontWeight: 'bold' }}>
            {t('PAGE_NOT_FOUND')}
          </Typography>
          <Typography variant={'h2'} style={{ paddingTop: 20 }}>
            {t('PAGE_NOT_FOUND_APOLOGY')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ paddingTop: 10, paddingBottom: 50 }}>
        <Grid container item xs={12} sm>
          <Grid md={6} xs={12} item className={classes.imageWrapper}>
            <img
              src={'/static/trees-in-the-pnw.png'}
              width={'351px'}
              height={'335px'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              textAlign: 'start',
              paddingTop: 60,
              paddingLeft: 65,
            }}
          >
            <Typography variant={'h1'} style={{ textAlign: 'start' }}>
              {t('PAGE_NOT_FOUND_HELPFUL_LINKS')}
            </Typography>
            <ul className={classes.links}>
              <li>
                <Link plain to={ROUTES.HELP}>
                  {t('Help Center')}
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.CONTACT_US}>
                  {t('Contact Us')}
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.ACCOUNT}>
                  {t('Account Summary')}
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.PAYMENT}>
                  {t('Pay Bill')}
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.VIEW_BILL}>
                  {t('View Bill')}
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.OUTAGES}>
                  {t('Report an Outage')}
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
